
.btn-squared-default-plain
{
    width: 100px !important;
    height: 100px !important;
    font-size: 10px;
}

    .btn-squared-default-plain:hover
    {
        border: 0px solid white;
    }