.sidebar {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        min-height: 100vh !important;
        z-index: 100;
        padding: 20px 0 0;
        box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
    }
    #sidebar-wrapper{
        min-height: 100vh !important;
        width: 50vw;
        margin-left: -1rem;
        -webkit-transition: margin .25s ease-out;
        -moz-transition: margin .25s ease-out;
        -o-transition: margin .25s ease-out;
        transition: margin .25s ease-out;
    }
    #sidebar-wrapper .sidebar-heading {
        padding: 0.875rem 1.25rem;
        font-size: 1.2rem;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

.recipeStep {
  border: 0;
}

.ingredient {
  border: 0;
}


.col-xs-05, .col-xs-115 {
    float: left;
    min-height: 1px;
    padding-left: 1px;
    padding-right: 1px;
    position: relative;
}
.col-xs-05 {
    width: 2%;
}
.col-xs-115 {
    width: 98%;
}

.NewNote form {
  padding-bottom: 15px;
}

.NewNote form textarea {
  height: 300px;
  font-size: 24px;
}


.list-group-item-plain {
  color: #555;
  background-color: #ffffff;
}
a.list-group-item-plain {
  color: #555;
}
a.list-group-item-plain .list-group-item-heading {
  color: inherit;
}
a.list-group-item-plain:hover,
a.list-group-item-plain:focus {
  color: #555;
  background-color: #ffffff;
}
a.list-group-item-plain.active,
a.list-group-item-plain.active:hover,
a.list-group-item-plain.active:focus {
  color: #fff;
  background-color: #ffffff;
  border-color: #3c763d;
}

.list-group-item-highlight {
  color: #3c763d;
  background-color: #dff0d8;
}
a.list-group-item-highlight {
  color: #3c763d;
}
a.list-group-item-highlight .list-group-item-heading {
  color: inherit;
}
a.list-group-item-highlight:hover,
a.list-group-item-highlight:focus {
  color: #3c763d;
  background-color: #dff0d8;
}
a.list-group-item-highlight.active,
a.list-group-item-highlight.active:hover,
a.list-group-item-highlight.active:focus {
  color: #fff;
  background-color: #dff0d8;
  border-color: #3c763d;
}

.list-group-item-grey {
  color: #848484;
  background-color: #e6e6e6;
/*
  font-size: 10px;
  line-height: 0.1;
*/
}
a.list-group-item-grey {
  color: #848484;
}
a.list-group-item-grey .list-group-item-heading {
  color: inherit;
}
a.list-group-item-grey:hover,
a.list-group-item-grey:focus {
  color: #848484;
  background-color: #e6e6e6;
}
a.list-group-item-grey.active,
a.list-group-item-grey.active:hover,
a.list-group-item-grey.active:focus {
  color: #fff;
  background-color: #848484;
  border-color: #848484;
}



/**
 * <div class="react-tags">
 *   <div class="react-tags__selected">
 *     <button class="react-tags__selected-tag">
 *       <span class="react-tags__selected-tag-name" />
 *     </button>
 *   </div>
 *   <div class="react-tags__search">
 *     <div class="react-tags__search-wrapper">
 *       <input class="react-tags__search-input" />
 *       <div />
 *     </div>
 *     <div class="react-tags__suggestions">
 *       <ul>
 *         <li class="is-active">
 *           <mark />
 *         </li>
 *         <li class="is-disabled">
 *           <mark />
 *         </li>
 *       </ul>
 *     </div>
 *   </div>
 */
.react-tags {
  position: relative;
  padding: 6px 0 0 6px;
  border: 1px solid #D1D1D1;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;
}

.react-tags.is-focused {
  border-color: #B1B1B1;
}

.react-tags__selected {
  display: inline;
}

.react-tags__selected-tag {
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  background: #F1F1F1;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__selected-tag:after {
  content: '\2715';
  color: #AAA;
  margin-left: 8px;
}

.react-tags__selected-tag:hover,
.react-tags__selected-tag:focus {
  border-color: #B1B1B1;
}

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__search {
    /* this will become the offsetParent for suggestions */
    position: relative;
  }

}

.react-tags__search-input {
  /* prevent autoresize overflowing the container */
  max-width: 100%;

  /* remove styles and layout from this element */
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
}

.react-tags__search-input::-ms-clear {
  display: none;
}

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 30em) {

  .react-tags__suggestions {
    width: 240px;
  }

}

.react-tags__suggestions ul {
  margin: 4px -1px;
  padding: 0;
  list-style: none;
  background: white;
  border: 1px solid #D1D1D1;
  border-radius: 2px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.react-tags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 6px 8px;
}

.react-tags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

.react-tags__suggestions li:hover {
  cursor: pointer;
  background: #eee;
}

.react-tags__suggestions li.is-active {
  background: #b7cfe0;
}

.react-tags__suggestions li.is-disabled {
  opacity: 0.5;
  cursor: auto;
}